import { InteractionType, LogLevel } from "@azure/msal-browser";
import { ProtectedResourceScopes } from "@azure/msal-angular";

export const environment = {
  production: false,
  apiEndpoint: 'https://dev-api-01.atompower.com/ev/v1',
  requireHttps: true,
  showDebugInformation: false,
  protocol: 'https',
  websocketProtocol: 'wss',
  apiKey: 'AIzaSyDIjN6pZ_W99CbTnyycq3U2r_YHu-6RYvw',
  msalConfig: {
    auth: {
      clientId: 'b32e391a-2b26-4e5b-9668-b1fd81f996fa',
      authority: 'https://atompowerdev.b2clogin.com/atompowerdev.onmicrosoft.com/B2C_1_signin',
      redirectUri: '/core',
      knownAuthorities: ['atompowerdev.b2clogin.com'],
      postLogoutRedirectUri: '/',
      navigateToLoginRequestUrl: false,
    },
    b2cPolicies: {
      names: {
        signIn: "B2C_1_signin",
      }
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        enableLogging: false,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    },
    guardConfig: {
      interactionType: InteractionType.Redirect,
      loginFailedRoute: "/"
    },
    interceptorConfig: {
      interactionType: InteractionType.Redirect,
      unprotectResourceMap: new Map<string, Array<string | ProtectedResourceScopes> | null>(),
      protectedResourceMap: new Map<string, Array<string | ProtectedResourceScopes> | null>()
        .set(
          'https://dev-api-01.atompower.com/ev/v1', ['https://atompowerdev.onmicrosoft.com/de76ec9c-eb58-420b-ba0a-5af49cb9af59/Installer.User']
        )
    }
  }
};
