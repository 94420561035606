import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { AuthService } from "./services/auth.service";
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimengModule } from "@global/primeng.module";

@NgModule({
  declarations: [
    FooterComponent,
    LoginComponent,
  ],
  imports: [
    PrimengModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommonModule,
    PrimengModule
  ],
  providers: [
    AuthService
  ]
})
export class GlobalModule { }
