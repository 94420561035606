import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import jwt_decode from 'jwt-decode';
import { TokenContents, UserCredential, } from "../models/auth-models";
import { Observable } from "rxjs";
import { Router } from '@angular/router';
import { ForgotPasswordModel } from '@global/models/forgot-password.model';
import { eula } from '@global/models/eula.model';
import { MsalService } from "@azure/msal-angular";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient,
    private router: Router,
    private msal: MsalService,
  ) {
  }

  logout(redirect?: boolean) {
    sessionStorage.clear();
    this.msal.logout();
  }

  getEula(): Observable<string> {
    return this.http.get<string>(`${environment.apiEndpoint}/users/eula`)
  }

  acceptEula(organizationId: string, data: eula): Observable<void> {
    return this.http.post<void>(`${environment.apiEndpoint}/organizations/${organizationId}/users/eula`, data);
  }

  getPermissions(): Observable<string> {
    return this.http.get<string>(`${environment.apiEndpoint}/auth/entrypoint`)
  }

  setOrgId(orgId: string) {
    this.setTokenOrganization(orgId)
  }

  async navToLogin(): Promise<void> {
    await this.router.navigateByUrl('login');
  }

  async navToDashboard(): Promise<void> {
    if (!this.eula) {
      await this.router.navigate(['core/profile']);
    } else {
      await this.router.navigate(['core']);
    }
  }

  get eula() {
    const decodedToken: TokenContents = jwt_decode(this.access_token);
    return decodedToken.user.eula;
  }

  get access_token(): string {
    const accessToken = sessionStorage.getItem('accessToken');
    return accessToken !== null ? accessToken : '';
  }

  get access_token_expiration(): number {
    const decodedToken: TokenContents = jwt_decode(this.access_token);
    return decodedToken.exp;
  }

  private setTokenOrganization(orgId: string): void {
    sessionStorage.setItem('organizationId', orgId);
  }

  get organizationId(): string {
    const orgId = sessionStorage.getItem('organizationId');
    return orgId !== null ? orgId : '';
  }

  get isAuthenticated(): boolean {
    return this.msal.instance.getAllAccounts().length > 0;
  }

  get superUser(): boolean {
    const decodedToken: TokenContents = jwt_decode(this.access_token);
    return decodedToken.user.superUser ? decodedToken.user.superUser : false;
  }

  get user_id(): string {
    const userId = sessionStorage.getItem('user_id');
    return userId !== null ? userId : '';
  }

  searchLocalStorageKeys(substring: string): string[] {
    const matchingKeys: string[] = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.includes(substring)) {
        matchingKeys.push(key);
      }
    }
    return matchingKeys;
  }

  getEvseApiAccessToken(): UserCredential | null {
    try {
      const protectedResourceMap = environment.msalConfig.interceptorConfig.protectedResourceMap;
      const firstResource = protectedResourceMap.values().next().value;

      if (!firstResource || firstResource.length === 0) {
        return null;
      }

      const prm = firstResource[0].toString().toLowerCase();
      const accessTokenList = this.searchLocalStorageKeys(prm);

      if (accessTokenList.length > 0) {
        const at = localStorage.getItem(accessTokenList[0]);
        if (at) {
          return JSON.parse(at) as UserCredential;
        }
      }
      return null;
    } catch (error) {
      return null;
    }
  }
}

