import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '@global/services/auth.service';
import {NotificationService} from "@global/services/notification/notification.service";
import {Organization, VerifyRequest} from "@global/models/auth-models";
import {Router} from "@angular/router";
import {MsalBroadcastService, MsalService} from "@azure/msal-angular";
import {filter} from "rxjs";
import {EventType, InteractionStatus} from "@azure/msal-browser";

@Component({
  selector: 'atom-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit {


  constructor() {

  }

  async ngOnInit(): Promise<void> {

  }
}
