import { isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { GlobalModule } from '@global/global.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { PrimengModule } from "@global/primeng.module";
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from "@azure/msal-angular";
import { environment } from "@environments/environment";
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import {RouterModule} from "@angular/router";

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: environment.msalConfig.auth.redirectUri,
      postLogoutRedirectUri: environment.msalConfig.auth.postLogoutRedirectUri,
      knownAuthorities: environment.msalConfig.auth.knownAuthorities,
      navigateToLoginRequestUrl: environment.msalConfig.auth.navigateToLoginRequestUrl
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback: environment.msalConfig.system.loggerOptions.enableLogging ? loggerCallback : undefined,
        logLevel: environment.msalConfig.system.loggerOptions.logLevel,
        piiLoggingEnabled: environment.msalConfig.system.loggerOptions.piiLoggingEnabled
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  // For routes that should not require authentication but share the baseUrl
  // These must be specified first.
  const unprotectResourceMap = environment.msalConfig.interceptorConfig.unprotectResourceMap;
  const protectedResourceMap = environment.msalConfig.interceptorConfig.protectedResourceMap;
  for (const [key, value] of unprotectResourceMap) {
    protectedResourceMap.set(key, value);
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  const scopes = Object.values(environment.msalConfig.interceptorConfig.protectedResourceMap).flat();
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...scopes],
    },
  };
}

@NgModule({ declarations: [
        AppComponent,
    ],
    exports: [
        PrimengModule
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], 
    imports: [
      RouterModule,
      BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        PrimengModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
        }),
        MsalModule,
        GlobalModule,
        NgxJsonViewerModule], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })

export class AppModule {

}
