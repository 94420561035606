import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from "@azure/msal-angular";
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [
  {
    path: 'core',
    canActivate: [MsalGuard],
    loadChildren: () => import('./core/core.module').then(c => c.CoreModule),
  },

  {
    path: '',
    redirectTo: 'core',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    bindToComponentInputs: true,
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
